import { TextureButton } from 'components/TextureButton';
import chevronLeftSrc from 'assets/lucky-draw/chevron-left.png';
import chevronRightSrc from 'assets/lucky-draw/chevron-right.png';

export const PaginationDots = () => {
  return (
    <div
      style={{
        width: '8%',
        aspectRatio: '1 / 1',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BC5F09',
        fontWeight: 600,
        fontSize: '2vh',
      }}
    >
      ...
    </div>
  );
};

export const PaginationButton = ({ page, active, onClick }) => {
  return (
    <div
      role='button'
      style={{
        width: '8%',
        aspectRatio: '1 / 1',
        backgroundColor: page === active && 'white',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#BC5F09',
        fontWeight: 600,
        fontSize: '2vh',
      }}
      onClick={onClick}
    >
      {page}
    </div>
  );
};

export const PaginationLeft = ({ onClick }) => {
  return (
    <TextureButton
      texture={chevronLeftSrc}
      style={{
        width: '8%',
        aspectRatio: '1/1',
        backgroundColor: 'white',
        borderRadius: '5px',
      }}
      onClick={onClick}
    />
  );
};

export const PaginationRight = ({ onClick }) => {
  return (
    <TextureButton
      texture={chevronRightSrc}
      style={{
        width: '8%',
        aspectRatio: '1/1',
        backgroundColor: 'white',
        borderRadius: '5px',
      }}
      onClick={onClick}
    />
  );
};
