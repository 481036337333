import rewardIconBackgroundSrc from 'assets/lucky-draw/reward-icon-background.png';
import buttonClaimSrc from 'assets/lucky-draw/button-claim.png';
import { TextureButton } from 'components/TextureButton';
import { useTranslation } from 'services/locale';

export const MissionItem = ({
  missionName,
  missionDesc,
  rewardIcon,
  urlMission,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: '100%',
        aspectRatio: '4.1341 / 1',
        // backgroundSize: 'cover',
        // backgroundImage: `url(${rewardItemBackgroundSrc})`,
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '8%',
        flexShrink: 0,
        backgroundColor: '#57976A',
        borderRadius: '3vw',
      }}
    >
      <div
        style={{
          height: '72%',
          marginLeft: '4%',
          marginRight: '4%',
          aspectRatio: '1 / 1',
          backgroundSize: 'cover',
          backgroundImage: `url(${rewardIconBackgroundSrc})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        <div
          style={{
            width: '86%',
            height: '86%',
            backgroundSize: 'cover',
            backgroundImage: `url(${rewardIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        ></div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          marginRight: '4%',
          flexShrink: 0,
          width: '40%',
        }}
      >
        <p
          style={{
            fontSize: '1.8vh',
            color: /* '#BC5F09' */ '#ffffff',
            fontWeight: 'bold',
          }}
        >
          {missionName}
        </p>
        <p
          style={{
            fontSize: '1.6vh',
            color: '#ffffff', //'rgba(188, 95, 9, 1)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {missionDesc}
        </p>
      </div>
      <TextureButton
        texture={buttonClaimSrc}
        onClick={() => {
          if (urlMission) {
            window.open(urlMission);
          }
        }}
        style={{
          width: '26%',
          flexShrink: 0,
          alignSelf: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      />
      {/* <button
          style={{
            fontSize: '3vw',
            padding: '2vw 4.4vw',
            appearance: 'none',
            backgroundColor: 'rgb(63, 180, 58)',
            border: 'none',
            borderRadius: '12%',
            color: 'white',
          }}
          onClick={() => {
            if (urlMission) {
              window.open(urlMission);
            }
          }}
        >
          {t('missions.get_it')}
        </button> */}
    </div>
  );
};
