import backIconSrc from 'assets/lucky-draw/back-icon.png';
import { useNavigate } from 'react-router-dom';
import { RewardList } from './RewardList';
import { useState } from 'react';
import { HistoryTable } from './HistoryTable';
import { useTranslation } from 'services/locale';

const RewardHistory = ({}) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('reward');
  const isRewardTab = currentTab === 'reward';
  const isHistoryTab = currentTab === 'history';
  const setRewardTab = () => setCurrentTab('reward');
  const setHistoryTab = () => setCurrentTab('history');
  const { t } = useTranslation();
  console.log('my item', t('reward_history.my_item'));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        position: 'relative',
      }}
    >
      <div
        style={{
          height: '3.2%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '2%',
        }}
      >
        <div
          style={{
            height: '100%',
            aspectRatio: '1 / 1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => navigate('/lucky-draw')}
        >
          <div
            style={{
              backgroundSize: 'cover',
              width: '100%',
              height: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${backIconSrc})`,
            }}
          ></div>
        </div>
        <p
          style={{
            fontSize: '2.4vh',
            fontWeight: 'bold',
            color: 'white',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: 'center',
            pointerEvents: 'none',
          }}
        >
          {t('reward_history.reward')}
        </p>
      </div>
      <div style={{ display: 'flex', paddingBottom: '3%', paddingTop: '2%' }}>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            color: isRewardTab ? 'white' : '#888',
            fontSize: '2.2vh',
            fontWeight: 500,
          }}
          onClick={setRewardTab}
        >
          {t('reward_history.my_item')}
        </div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            color: isHistoryTab ? 'white' : '#888',
            fontSize: '2.2vh',
            fontWeight: 500,
          }}
          onClick={setHistoryTab}
        >
          {t('reward_history.history')}
        </div>
      </div>
      {isRewardTab ? <RewardList /> : <HistoryTable />}
    </div>
  );
};

export default RewardHistory;
