import rewardItemBackgroundSrc from 'assets/lucky-draw/reward-item-background-old.png';
import rewardIconBackgroundSrc from 'assets/lucky-draw/reward-icon-background.png';
// import rewardImgSrc from '../../assets/251d42b9-d87c-491b-a4b6-304860671c57.png';
import { formatDate } from 'services/formatDate';

export const RewardItem = ({ reward }) => {
  const rewardDate = reward?.createdAt
    ? formatDate(new Date(reward.createdAt))
    : null;
  const rewardImg = reward?.metadata?.rewardIcon;

  return (
    <div
      style={{
        width: '100%',
        aspectRatio: '4.1341 / 1',
        backgroundSize: 'cover',
        backgroundImage: `url(${rewardItemBackgroundSrc})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        gap: '4%',
        position: 'relative',
        borderRadius: '8%',
        flexShrink: 0,
      }}
    >
      <div
        style={{
          height: '72%',
          marginLeft: '4%',
          aspectRatio: '1 / 1',
          backgroundSize: 'cover',
          backgroundImage: `url(${rewardIconBackgroundSrc})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        <div
          style={{
            width: '86%',
            height: '86%',
            backgroundSize: 'cover',
            backgroundImage: `url(${rewardImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        ></div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          flexShrink: 0,
          width: '80%',
        }}
      >
        <p style={{ fontSize: '4vw', color: '#BC5F09' }}>
          {reward?.metadata?.rewardName}
        </p>
        <p
          style={{
            fontSize: '3vw',
            color: 'rgba(188, 95, 9, 1)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {rewardDate}
        </p>
      </div>
    </div>
  );
};
