import { useEffect, useState } from 'react';
import { usePagination } from './usePagination';
import apis from 'services/apis';
import './HistoryTable.css';
import {
  PaginationButton,
  PaginationDots,
  PaginationLeft,
  PaginationRight,
} from './Pagination';
import { formatDate } from 'services/formatDate';
import { useTranslation } from 'services/locale';

const HISTORY_ROWS_COUNT = 15;

export const HistoryTable = () => {
  const [historyItems, setHistoryItems] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { range, next, previous, setPage, active } = usePagination({
    total: totalPage,
    page: currentPage,
    onChange: setCurrentPage,
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchRewardHistory(active);
  }, [active]);

  const fetchRewardHistory = (pageIndex) => {
    const body = {
      gameId: 'lucky_draw_game',
      limit: HISTORY_ROWS_COUNT,
      skip: (pageIndex - 1) * HISTORY_ROWS_COUNT,
      rewardItem: 'reward',
    };

    apis.getMyGift({ data: body }).then((rs) => {
      setTotalPage(Math.ceil(rs.count / HISTORY_ROWS_COUNT));
      setHistoryItems(rs.data);
      console.log('rs ', rs);
    });
  };

  return (
    <div
      style={{
        padding: '0% 4%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ height: '86%' }}>
        <table className='history-table'>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>{t('reward_history.reward')}</th>
              <th style={{ width: '40%' }}>{t('reward_history.day')}</th>
            </tr>
          </thead>
          <tbody>
            {historyItems?.map((item) => (
              <tr>
                <td>{item?.metadata?.rewardName}</td>
                <td>
                  {item?.createdAt && formatDate(new Date(item?.createdAt))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          gap: 4,
        }}
      >
        <PaginationLeft onClick={previous} />
        {range.map((page) => {
          if (page === 'dots') {
            return <PaginationDots />;
          }

          return (
            <PaginationButton
              page={page}
              active={active}
              onClick={() => setPage(page)}
            />
          );
        })}
        <PaginationRight onClick={next} />
      </div>
    </div>
  );
};
