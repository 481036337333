export function formatDate(
  inputDate,
  { padZero = true, isMyanmar = false } = {}
) {
  let date = inputDate.getDate();
  let month = inputDate.getMonth() + 1;
  let year = inputDate.getFullYear();

  date = date.toString();
  month = month.toString();
  year = year.toString();

  if (padZero) {
    date = date.padStart(2, '0');
    month = month.padStart(2, '0');
  }

  if (isMyanmar) {
    date = date
      .split('')
      .map((digit) => myanmarDigitMap[digit])
      .join('');
    month = month
      .split('')
      .map((digit) => myanmarDigitMap[digit])
      .join('');
    year = year
      .split('')
      .map((digit) => myanmarDigitMap[digit])
      .join('');
  }

  return `${date}/${month}/${year}`;
}

const myanmarDigitMap = {
  0: '၀',
  1: '၁',
  2: '၂',
  3: '၃',
  4: '၄',
  5: '၅',
  6: '၆',
  7: '၇',
  8: '၈',
  9: '၉',
};
