import config from './config';
// import DeviceDetector from 'device-detector-js';
// const deviceDetector = new DeviceDetector();
const HOST = config.HOST;
const paths = {
  getGameInfo: '/api/loyalty/game_info',
  getRandomCode: '/api/loyalty/get_event_randomcode',
  getRankingSpin: '/api/loyalty/get_ranking_spin',
  getGameReward: '/loyalty/gamification/myGift',
  getSpinCount: '/api/loyalty/get_spin_count',
  getGameInfo: '/api/loyalty/game_info',
  getMyGift: '/loyalty/gamification/myGift',
  validateGameTurn: '/api/loyalty/validate_game_turn',
};

const apis = {};
const entriesPath = Object.entries(paths);

for (let i = 0; i < entriesPath.length; i++) {
  const [key, value] = entriesPath[i];
  apis[key] = async ({ data, method = 'POST' }) => {
    const rs = await fetch(HOST + value, {
      method,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        // device: JSON.stringify(deviceDetector.parse(navigator.userAgent)),
      },
    });
    const result = await rs.json();
    return result;
  };
}

export default apis;
