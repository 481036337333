export const TextureButton = ({ texture, style, imageStyle, ...props }) => {
  return (
    <div
      role="button"
      style={{ ...defaultStyle.container, ...style }}
      {...props}
    >
      <img style={{ ...defaultStyle.img, ...imageStyle }} src={texture} />
    </div>
  );
};

const defaultStyle = {
  container: {
    userSelect: "none",
    cursor: "pointer",
    overflow: "hidden",
  },
  img: {
    width: "100%",
    objectFit: "cover",
  },
};
