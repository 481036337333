import { RewardItem } from './RewardItem';
import { usePagination } from './usePagination';
import { useEffect, useState } from 'react';
import apis from 'services/apis';
import {
  PaginationButton,
  PaginationDots,
  PaginationLeft,
  PaginationRight,
} from './Pagination';

const REWARDS_PER_PAGE = 7;

export const RewardList = () => {
  const [rewards, setRewards] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { range, next, previous, setPage, active } = usePagination({
    total: totalPage,
    page: currentPage,
    onChange: setCurrentPage,
  });

  useEffect(() => {
    fetchRewardList(active);
  }, [active]);

  const fetchRewardList = (pageIndex) => {
    const body = {
      gameId: 'lucky_draw_game',
      limit: REWARDS_PER_PAGE,
      skip: (pageIndex - 1) * REWARDS_PER_PAGE,
      rewardItem: 'reward',
      rewardType: {
        '!=': 'none',
      },
    };

    apis.getMyGift({ data: body }).then((rs) => {
      setTotalPage(Math.ceil(rs.count / REWARDS_PER_PAGE));
      setRewards(rs.data);
    });
  };

  return (
    <div
      style={{
        padding: '0% 4%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '86%',
          aspectRatio: '4 / 7',
        }}
      >
        {rewards?.map((reward) => (
          <RewardItem reward={reward} key={reward.id} />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          gap: 4,
          width: '100%',
        }}
      >
        <PaginationLeft onClick={previous} />
        {range.map((page) => {
          if (page === 'dots') {
            return <PaginationDots />;
          }

          return (
            <PaginationButton
              page={page}
              active={active}
              onClick={() => setPage(page)}
            />
          );
        })}
        <PaginationRight onClick={next} />
      </div>
    </div>
  );
};
