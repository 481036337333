import { MissionItem } from './MissionItem';
import backIconSrc from 'assets/lucky-draw/back-icon.png';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { locale, useTranslation } from 'services/locale';

const MissionList = () => {
  const navigate = useNavigate();
  const { gameInfo } = useOutletContext();
  const missions = gameInfo?.missions;
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        position: 'relative',
      }}
    >
      <div
        style={{
          height: '3.2%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '2%',
          position: 'relative',
        }}
      >
        <div
          style={{
            height: '100%',
            aspectRatio: '1 / 1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => navigate('/lucky-draw')}
        >
          <div
            style={{
              backgroundSize: 'cover',
              width: '100%',
              height: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${backIconSrc})`,
            }}
          ></div>
        </div>
        <p
          style={{
            fontSize: '2.4vh',
            fontWeight: 'bold',
            color: 'white',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: 'center',
            pointerEvents: 'none',
          }}
        >
          {t('missions.missions')}
        </p>
      </div>
      <div
        style={{
          padding: '0% 4%',
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3vw',
            height: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {missions?.map((mission) => (
            <MissionItem
              key={mission.missionId}
              missionDesc={mission?.[`missionDesc_${locale}`]}
              missionName={mission?.[`missionName_${locale}`]}
              urlMission={mission.urlMission}
              rewardIcon={mission.rewardIcon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MissionList;
