const translations = {
  my: {
    reward_history: {
      my_item: 'ငါ့ပစ္စည်း',
      reward: 'ဆုလာဘ်',
      history: 'သမိုင်း',
      day: 'နေ့',
    },
    missions: {
      missions: 'မစ်ရှင်များ',
      get_it: 'သွားတော့',
    },
    main: {
      spin_chance: 'လှည့်ပတ်မှုအခွင့်အလမ်း',
      out_of_spin_title: 'လှည့်ဖျား၏။',
      out_of_spin_content:
        'အခမဲ့လှည့်ခြင်းတစ်ခုရယူရန် ငွေဖြည့်ပါ သို့မဟုတ် မနက်ဖြန်ပြန်လာပါ။',
      result_popup: {
        you_have_won: 'မင်းအနိုင်ရခဲ့တယ်။',
        congratulation: 'ဂုဏ်ယူပါသည်။',
        universe_msg: 'သင်သည် စကြဝဠာထံမှ မက်ဆေ့ချ်တစ်ခုကို လက်ခံရရှိခဲ့သည်။',
      },
      error: 'အမှား',
    },
  },
  en: {
    reward_history: {
      my_item: 'My Item',
      reward: 'Reward',
      history: 'History',
      day: 'Day',
    },
    missions: {
      missions: 'Missions',
      get_it: 'Get it',
    },
    main: {
      spin_chance: 'spin chance',
      out_of_spin_title: 'Out of Spin',
      out_of_spin_content: 'Topup or come back tomorrow to get one free spin.',
      result_popup: {
        you_have_won: 'You have won',
        congratulation: 'Congratulation!',
        universe_msg: 'You received a message from the Universe.',
      },
      error: 'Error',
    },
  },
};

const params = new URLSearchParams(window.location.search);
let _locale = params.get('locale') || sessionStorage.getItem('locale');

if (_locale !== 'en' && _locale !== 'my') {
  console.warn(
    `Locale must be one of ['en', 'my'], got ${_locale}. Using the default en locale instead.`
  );
  _locale = 'en';
}

export const locale = _locale;

/**
 * @typedef {{[K in keyof T]: T[K] extends Record<string, unknown>
 * ? Join<K, Paths<T[K]>>
 * : K;
 * }[keyof T]} Paths
 * @template T
 */

/**
 * @typedef {S1 extends string ? S2 extends string ? `${S1}.${S2}` : never : never} Join
 * @template S1,S2
 */

const trans = translations[locale];

/**
 *
 * @param {string} keys
 */
const getTran = (keys) => {
  let tran = trans;
  keys.split('.').forEach((k) => (tran = tran[k]));
  return tran;
};

export const useTranslation = () => {
  return {
    /**
     *
     * @param {Paths<typeof translations['en']>} keys
     * @returns
     */
    t: (keys) => getTran(keys),
  };
};
